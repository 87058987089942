import React, {  useEffect } from "react";
// import { Formik } from 'formik';
import { removeCanvas } from "../utils";
// import { sendEmail } from "../email";

export default function Contact() {
  useEffect(() => {
    removeCanvas();
  }, []);
  return (
    <section className="contact-form grid place-items-center">
      <div className="max-w-6xl h-42 bg-white antialiased">
        <h1 className="text-2xl mt-5 md:text-2xl uppercase font-bold pt-20 pb-5 text-center">
          Contact
        </h1>
      </div>

      <p className="mb-3 mt-2 text-xl">Feel free to reach out! </p>
      <div className="mb-3 justify-items-center">
        <a
          className="hover:text-sky-500"
          href="https://github.com/heregoes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon
            name="logo-github"
            style={{ fontSize: "25px", margin: "5px" }}
          ></ion-icon>
        </a>
        <a className="hover:text-sky-500" href="https://www.linkedin.com/in/gabrielnyante" target="_blank" rel="noopener noreferrer">
          <ion-icon
            name="logo-linkedin"
            style={{ fontSize: "25px", margin: "5px" }}
          ></ion-icon>
        </a>
        <a
        className="hover:text-sky-500"
          href="https://medium.com/@gabnyante"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon
            name="logo-medium"
            style={{ fontSize: "25px", margin: "5px" }}
          ></ion-icon>
        </a>
        <a
        className="hover:text-sky-500"
          href="https://mirror.xyz/heregoes.eth"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon
            name="book-outline"
            style={{ fontSize: "25px", margin: "5px" }}
          ></ion-icon>
        </a>
      </div>
      <div className="justify-center">Email me at
        <a className="no-underline hover:underline text-sky-500" href="mailto:hello@gabrielnyante.com" rel="noopener noreferrer"> hello@gabrielnyante.com</a>
      </div>
      {/* <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        // const data = new FormData(form);
        // const action = e.target.action;
        fetch('https://script.google.com/macros/s/AKfycbxPVUX4xdc_GFMuCwYYNCDp-AC7u6Oq8Kkru6d7Me32VNkKl-rublCqWVla_IyXo1um/exec', {
          method: 'POST',
          body: values,
        })
        .then(() => {
          alert("Success!");
          setSubmitting(false);
        })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className="w-full max-w-lg" onSubmit={handleSubmit} action={process.env.WEB_APP_URL} method="post">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <input
                className="contact-input appearance-none block w-full text-gray-700 border border-black-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                name='name'
                placeholder="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <input
                className="contact-input appearance-none block w-full text-gray-700 border border-black-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="email"
                name='email'
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />
              {errors.email && touched.email && errors.email}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <textarea
                className="contact-input appearance-none block w-full text-gray-700 border border-black-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="message"
                name='message'
                placeholder="Message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                required
              />
              {errors.message && touched.message && errors.message}
            </div>
          </div>
          {errors.message && touched.message && errors.message}
          <button type="submit" disabled={isSubmitting ? true : false} className="contact-submit-btn">
          {isSubmitting ? "Sending..." : "Send"}
          </button>
        </form>
      )}
    </Formik> */}
    </section>
  );
}