import React from "react";

function PlayButtons({ props }) {
  const { isPlaying, pauseVideo, playVideo, vidClicked } = props;
  return (
    <>
      {!vidClicked ? (
        <div className="press-play-tooltip text-sm" onClick={playVideo}>
          Press Play
        </div>
      ) : null}
      {isPlaying ? (
        <ion-icon
          name="pause-outline"
          className="mediaBtn"
          style={{
            marginRight: "1.5rem",
            fontSize: "30px",
            color: "white",
          }}
          onClick={pauseVideo}
        ></ion-icon>
      ) : (
        <ion-icon
          name="play-outline"
          className="mediaBtn"
          style={{
            marginRight: "1.5rem",
            color: "white",
            fontSize: "30px",
          }}
          onClick={playVideo}
        ></ion-icon>
      )}
    </>
  );
}

export default PlayButtons;
