import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { removeCanvas } from "../utils";

const experiments = [
  {
    title: "Porticus | Virtual Art Museum",
    titleColor: "white",
    description: "",
    link: "https://www.porticus.tech/",
    background: "bg-sky-800",
  },
  {
    title: "News App",
    titleColor: "white",
    description: "",
    link: "https://github.com/heregoes",
    background: "bg-red-600",
  },
  {
    title: "AR and VR for Charlotte",
    titleColor: "white",
    description: "",
    link: "https://dev.d3cfj8o2hey648.amplifyapp.com/",
    background: "bg-orange-500",
  },
  {
    title: "AR Media Player",
    titleColor: "white",
    description: "",
    link: "/media",
    linkComp: true,
    background: "bg-green-800",
  },
];

export default function Projects() {
  useEffect(() => {
    removeCanvas();
  }, []);
  return (
    <section className="grid place-items-center bg-scroll">
      <div className="max-w-6xl mx-auto h-42 bg-white antialiased">
        <h1 className="text-2xl mt-5 md:text-2xl uppercase font-bold pt-20 pb-10 text-center">
          Experiments
        </h1>
      </div>
      {/* Grid starts here */}
      <div className="grid grid-cols-2 gap-2 ml-4 mr-4 work-collection">
        {experiments.map((item) => {
          if (item && item.linkComp) {
            <Link to="/media" className={`work-content-item glass ${item.background}`}>
              <h5 className="text-white text-xl">{item.title}</h5>
              <div className="portfolio-item-category">{item.description}</div>
            </Link>;
          }
          return (
            <a
              href={item.link}
              className={`work-content-item glass ${item.background}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: item.titleColor }}
            >
              <h5 className="portfolio-item-title text-xl">{item.title}</h5>
              <div className="portfolio-item-category">{item.description}</div>
            </a>
          );
        })}
      </div>
    </section>
  );
}
