import React, { useEffect } from "react";
import { removeCanvas } from "../utils";

function Blog() {
  useEffect(() => {
    removeCanvas();
  }, []);

  return (
    <section className="contact-form grid place-items-center">
      <div className="max-w-6xl h-42 bg-white antialiased">
        <h1 className="text-3xl md:text-4xl uppercase font-bold mt-5 pt-20 pb-10 text-center">
          Blog
        </h1>
      </div>
      <p className="container text-md  md:text-lg text-justify mb-10 w-full md:w-2/3">
        I publish articles on three different platfroms, ranging on topics such
        as Augmented & Virtual Reality, Artificial Intelligence, Software
        Development & Design, Modern Philosophy, and more. The content on each
        platform is similar but each is geared more toward a specific focus
        subject.
      </p>
      <ul className="list-disc">
        <li className="mb-3">
          <a
            href="https://creative-development.ghost.io//"
            className="text-xl underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creative Development
          </a>
        </li>
        <li className="mb-3">
          <a
            href="https://mirror.xyz/heregoes.eth"
            target="_blank"
            className="text-xl underline"
            rel="noopener noreferrer"
          >
            Mirror.xyz
          </a>
        </li>
        <li className="mb-3">
          <a
            href="https://medium.com/@gabnyante"
            className="text-xl underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium
          </a>
        </li>
      </ul>
    </section>
  );
}

export default Blog;
