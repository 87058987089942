import React from "react";

function MuteButtons({props}) {
  const { muteVideo, mutedVideo } = props
  return (
    <>
      {mutedVideo ? (
        <ion-icon
          onClick={muteVideo}
          className="mediaBtn"
          style={{
            fontSize: "30px",
            color: "white",
          }}
          name="volume-mute"
        ></ion-icon>
      ) : (
        <ion-icon
          onClick={muteVideo}
          className="mediaBtn"
          style={{
            fontSize: "30px",
            color: "white",
          }}
          name="volume-high"
        ></ion-icon>
      )}
    </>
  );
}

export default MuteButtons;
