import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import MediaPlayer from "./pages/sub/MediaPlayer";
import Secret from "./pages/Secret2";
import "./App.css";

function App() {

  const routes = [
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/blog", element: <Blog /> },
    { path: "/work", element: <Work /> },
    { path: "/contact", element: <Contact /> },
    { path: "/media", element: <MediaPlayer /> },
    { path: '/secret', element: <Secret />},
    { path: "*", element: <NotFound /> },
  ];

  return (
    <BrowserRouter>
      <Header />
        <Routes>
          {routes.map(({ path, element }) => {
            return <Route path={path} element={element} exact />;
          })}
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
