import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  const location = useLocation();
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const siteLinks = [
    {
      to: "/",
      text: "Home",
    },
    {
      to: "/about",
      text: "About",
    },
    {
      to: "/work",
      text: "Work",
    },
    {
      to: "/contact",
      text: "Contact",
    },
  ];

  return (
    <div className="header">
      <div className="topleft">
        <Link to="/">
          <p
            className="text-4xl"
            style={{ color: pathname === "/" ? "white" : "black" }}
          >
            GN
          </p>
        </Link>
      </div>
      <div className="dropdown">
        <button className="dropbtn">
          <ion-icon
            name="reorder-three-outline"
            style={{
              color: pathname === "/" ? "white" : "black",
              fontSize: "50px",
            }}
          ></ion-icon>
        </button>
        <div
          id="dropdown-content"
          className={`dropdown-content font-bold uppercase ${pathname === "/" ? "text-white" : "text-black"
            }`}
          style={{
            border: pathname === "/" ? "white 0.25px solid" : "black 1px solid",
          }}
        >
          {siteLinks.map((link) => {
            return (
              <Link
                key={link.text}
                to={link.to}
                style={{
                  borderBottom:
                    pathname === "/"
                      ? "0.25px solid white"
                      : "0.25px solid black",
                }}
              >
                {link.text}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
