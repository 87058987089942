import React, { useState, useEffect } from "react";
import { mobileAndTabletCheck, addCanvas } from "../utils";
import "aframe";
import "aframe-extras";
import { Scene } from "aframe-react";
import PlayButtons from "../components/PlayButtons";
import MuteButtons from "../components/MuteButtons";

const Home = () => {
  const [mutedVideo, setMutedVideo] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [vidClicked, setVidClicked] = useState(false);

  const muteVideo = () => {
    const video = document.getElementById("vid360");
    setMutedVideo(!mutedVideo);
    video.muted = !mutedVideo;
  };

  const playVideo = () => {
    const video360 = document.getElementById("vid360");
    if (!video360) return 
    const playPromise = video360.play();
    // In browsers that don’t yet support this functionality,
    // playPromise won’t be defined.
    if (playPromise !== undefined || playPromise !== null ) {
      playPromise
        .then(function () {
          console.log("video360 played");
          // Automatic playback started!
        })
        .catch(function (error) {
          console.error("video360 error", error);
          return (
            <div className="home-title text-4xl md:text-6xl font-bold py-7 text-center md:text-left z-10">
              Error: {error}
            </div>
          );
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
      setVidClicked(true);
      setIsPlaying(true);
    } else {
      console.error("video360 not defined");
      return (
        <div className="home-title text-4xl md:text-6xl font-bold py-7 text-center md:text-left z-10">
          Device Not Supported
        </div>
      );
    }
  };

  const pauseVideo = () => {
    const video360 = document.getElementById("vid360");
    video360.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      var scene = document.querySelector("a-scene");
      var splash = document.querySelector("#splash");
      scene.addEventListener("loaded", function (e) {
        splash.style.display = "none";
      });
    });
  }, []);

  useEffect(() => {
    addCanvas();
    if (!mobileAndTabletCheck()) {
      setTimeout(() => {
        playVideo();
      }, 5000);
    }
  }, [playVideo]);

  return (
      <Scene loading-screen="dotsColor: white; backgroundColor: black;">
        <a-assets>
          <video
            id="vid360"
            src="https://s3.us-east-1.wasabisys.com/portfolio-site/portfolioSite360.mp4"
            preload="auto"
            crossOrigin="anonymous"
            muted
            loop
          ></video>
        </a-assets>
        <div id="splash">
          <div className="loading"></div>
        </div>
        <div className="home" id="galleryContent">
          <h2
            className="home-title py-7 text-center md:text-left z-10"
            style={{ textAlign: "center" }}
          >
            <span className="home-title-span text-white text-center">
              Building{" "}
              <span className="animate-charcter">digital experiences</span> at
              the intersection of <span className="animate-charcter">Art</span>,{" "}
              <span className="animate-charcter">Technology</span>, and{" "}
              <span className="animate-charcter">Social Commentary</span>
            </span>
          </h2>
        </div>
        <div className="home-video-controls">
          <PlayButtons props={{isPlaying, pauseVideo, playVideo, vidClicked}} />
          <MuteButtons props={{mutedVideo, muteVideo}}/>
        </div>
        {vidClicked ? (
          <a-videosphere src="#vid360" rotation="0 60 0"></a-videosphere>
        ) : (
          <a-sky src="#homeImg" color='black' rotation="0 60 0"></a-sky>
        )}
        <a-sky src="#homeImg" color='black' rotation="0 60 0"></a-sky>
      </Scene>
  );
};

export default Home;
