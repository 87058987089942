import React from "react";

const Footer = () => {
  return (
    <footer className="nk-footer animated fadeIn">
      <div className="footerContent">
        {/* <div className="bottomleft">
          <a
            href="https://github.com/heregoes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="logo-github" size="medium"></ion-icon>
          </a>
          <a href="https://www.linkedin.com/in/gabriel-nyante-028b38117/" target="_blank"><ion-icon name="logo-linkedin" size="medium"></ion-icon></a>
          <a
            href="https://medium.com/@gabnyante"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="logo-medium" size="medium"></ion-icon>
          </a>
          <a
            href="https://twitter.com/gabekofi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="logo-twitter" size="medium"></ion-icon>
          </a>
          <a
            href="https://mirror.xyz/heregoes.eth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="book-outline" size="medium"></ion-icon>
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
