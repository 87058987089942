import React, { useEffect } from "react";
import { removeCanvas } from "../utils";

function NotFound() {
  useEffect(() => {
    removeCanvas();
  }, []);
  return (
    <section className="contact-form grid place-items-center">
      <div className="max-w-6xl h-42 bg-white antialiased mt-5">
        <h1 className="text-3xl md:text-4xl font-bold py-20 text-center">
          404 Page Not Found
        </h1>
      </div>
    </section>
  );
}

export default NotFound;
