import React, { useEffect } from "react";

function MediaPlayer() {
  useEffect(() => {
    const canvas = document.querySelector("canvas");
    if (canvas) canvas.style.display = "none";
  }, []);
  return (
    <section className="grid place-items-center bg-scroll mediaPlayer">
      <div className="max-w-6xl mx-auto h-42 bg-white antialiased p-5">
        <h1 className="text-3xl mt-5 md:text-4xl uppercase font-bold pt-20 pb-10 text-center">
          Media Player
        </h1>
        <p className="mb-3">
          1. Visit this link on a mobile device:{" "}
          <a
            href="https://minimal-ar-media-player.vercel.app"
            className="text-blue-500 font-bold"
          >
            minimal-ar-media-player.vercel.app
          </a>
        </p>
        <p className="mb-6">
          2. Scan the QR Code below to view the Augmented Reality Experience
        </p>
        <div className="grid grid-cols-2 gap-2 ml-4 mr-4 work-collection">
          <img
            className="markerImg text-center "
            src="./assets/images/main.jpg"
            crossOrigin="anonymous"
            alt="home"
          />
          <iframe
            title="AR Media Player"
            className="mediaPlayerVideo text-center"
            src="https://s3.us-east-1.wasabisys.com/portfolio-site/mediaplayer.mp4"
          />
        </div>
      </div>
    </section>
  );
}

export default MediaPlayer;
