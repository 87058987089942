import React, { useState, useEffect } from "react";
import { removeCanvas } from "../utils";

function Secret() {
  const [passcode, setPasscode] = useState("");
  const [correctPasscode, setCorrectPasscode] = useState(true);
  useEffect(() => {
    removeCanvas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("passcode", passcode);
    if (passcode === "secret") {
      console.log("correct", passcode);
      setCorrectPasscode(false);
    } else {
      document.querySelector(".contact-submit-btn").innerText =
        "Incorrect passcode";
    }
  };
  const handleChange = (e) => {
    // e.preventDefault()
    document.querySelector(".contact-submit-btn").innerText = "Submit";
    setPasscode(e.target.value);
  };

  return (
    <div className="flex items-center justify-center mt-40 sm:mt-60">
      {correctPasscode ? (
        <div className="bg-white text-black font-bold rounded-lg p-5">
          <form onSubmit={handleSubmit}>
            <h1 className="text-5xl">What's The Passcode?</h1>
            <input
              className="contact-input mt-10 appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="password"
              placeholder="Passcode"
              value={passcode}
              onChange={handleChange}
            />
            <button className="contact-submit-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : (
        <>welcome :)</>
      )}
    </div>
  );
}

export default Secret;
