import React, { useEffect, useRef } from "react";
import { removeCanvas } from "../utils";
import ReactPlayer from 'react-player'
const VIDEO_PATH = 'https://s3.us-east-1.wasabisys.com/portfolio-site/ncbceInterview.mp4';

const About = () => {
  useEffect(() => {
    removeCanvas();
  }, []);

  const playerRef = useRef(null);

  return (
    <section className="about grid place-items-center p-5 mb-5 z-10">
      <div className="max-w-6xl h-42 bg-white antialiased">
        <h1 className="text-2xl md:text-2xl uppercase font-bold pt-20 text-center">
          About Me
        </h1>
      </div>
      <p className="mt-5 md:mt-0 text-md mb-3 md:text-md text-justify w-full md:w-2/3">
        I believe Augmented Reality and Virtual Reality will fundamentally
        change how we interact with our physical and digital worlds. I also
        believe Blockchain & Crypto will transform the world, not just
        financially but in almost every facet of contracts, agreements, media,
        and mediaries.
        <br />
        I'm currently developing Web based AR & VR Experiences as well as
        experimenting with applications that blend the two worlds together. My
        technical background is in Software Development and I've consultanted
        with Government Agencies and enterprise to small sized businesses on
        solutions ranging from SaaS, E-commerce, Database Integrations, and
        Mobile Apps.
        <h2 className="mt-5 text-lg text-center font-bold underline uppercase">
        Interviews
      </h2>
      <ReactPlayer className="ncbceInterview" ref={playerRef} url={VIDEO_PATH} controls={true} />
      <p className="-mt-6 italic text-sm text-center">
        January 2021
      </p>
      </p>

    </section>
  );
};

export default About;
